<template>
    <section id="dashboard-ecommerce" v-if="$can('read','pofu')">
      
      <div>

          <b-card>
            <div>
              <h5>Select Fields</h5>
             <b-row>
                <b-col md="1" xl="1" xs="1" class="mt-3">
                  <span variant="outline-secondary" type="submit"  @click="clearSelected" >Clear</span>
                </b-col>
                <b-col md="11" xl="11" xs="1">
                  <b-form-group class="demo-inline-spacing">
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selected"
                      :options="POFUOptions"
                      name="fields-1"
                      class="demo-inline-spacing"
                    />
                  </b-form-group>  
                </b-col> 
              </b-row>
              <b-row>
                <b-col col v-if="$can('read','manager_filter')">
                  <b-form-group
                    label="Team Lead"
                    label-for="accountMgr"
                  >
                    <v-select
                        v-model="mgrSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="accountMgr"
                        :reduce="(option) => option.emp_id"
                        ref="accMgr"
                      />
                  </b-form-group>
                </b-col>
                <b-col col v-if="$can('read','recruiter_filter')">
                  <b-form-group
                    label="Recruiter"
                    label-for="recruiter"
                  >
                    <v-select
                        v-model="recSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="recruiters"
                        :reduce="(option) => option.emp_id"
                        ref="rec"
                      />
                  </b-form-group>
                </b-col>
                <b-col col>
                  <b-form-group
                    label="Location"
                    label-for="location"
                  >
                    <v-select
                        v-model="locSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="location"
                        :reduce="(option) => option.title"
                        ref="loc"
                      />
                  </b-form-group>
                </b-col>
                <b-col col>
                  <b-form-group
                    label="Client"
                    label-for="client"
                  >
                    <v-select
                        v-model="clientSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="clients"
                        :reduce="(option) => option.title"
                        ref="client"
                      />
                  </b-form-group>
                </b-col>
                <b-col col>
                  <b-form-group
                    label="Source"
                    label-for="source"
                  >
                    <v-select
                        v-model="sourceSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="sources"
                        :reduce="(option) => option.title"
                        ref="source"
                      />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <!-- <b-col md="4" xl="4" xs="4" class="mb-1">
                  <b-form-group
                    label="Start Date"
                    label-for="start-date"
                  >
                    <flat-pickr
                      v-model="startDate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                    />
                    <small v-if="!startDate" class="text-danger">Start Date is Mandatory</small>
                  </b-form-group>
                </b-col>    
                <b-col md="4" xl="4" xs="4" class="mb-1">
                  <b-form-group
                    label="End Date"
                    label-for="end-date"
                  >
                    <flat-pickr
                      v-model="endDate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                    />
                    <small v-if="!endDate" class="text-danger">End Date is Mandatory</small>
                  </b-form-group>
                </b-col> -->
                <b-col md="4" xl="4" xs="4" class="mb-1">
                  <b-form-group
                    label=""
                    label-for="">
                    <b-button variant="outline-primary" value="submit" id="submit" type="submit" width="100%" @click="getReport" style="margin-top: 20px">Get Report</b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <div v-if="loading">
                  <div class="text-center">
                    <b-spinner variant="primary" label="Text Centered" />
                  </div>
              </div>
              <div v-if="error" class="text-danger">{{ error }}</div>
              <div v-if="!loading && compiledData">
                
                <download-excel :data="compiledData" :name="filename" v-if="isReadyToDownload && $can('read','recruiter_filter')" style="max-width: 170px">
                  <button type="button" class="btn btn-success mb-1">Download Report</button>
                </download-excel>
                <b-row>
      
                  <b-col
                    md="4"
                    sm="4"
                    lg="4"
                    class="my-1"
                    style="font-weight: 430;"
                  > Total Candidates : {{ totalRows }}
                </b-col>
                  <b-col
                    md="8"
                    sm="8"
                    lg="8"
                    class="my-1"
                  >
                    <b-form-group
                      label="Filter"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="filterInput"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filterInput"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        />
                        <b-input-group-append>
                          <b-button
                            :disabled="!filter"
                            @click="filter = ''"
                          >
                            Clear
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              <!-- <b-table striped hover responsive :items="compiledData"></b-table> -->
              <b-table
                striped
                hover
                responsive
                :items="compiledData"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(pofu_id)="data" >
                      <div class="text-nowrap text-success" @click="showPOFUTimeline(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-pofu-timeline>
                          {{ data.item.pofu_id }} 
                      </div>
                  
                </template>
                <template #cell(candidate_name)="data">
                  <router-link
                                :to="{
                                  name: 'apps-candidates-view',
                                  params: { id: data.item.cid, jobId: data.item.jid },
                                  query: { position_name: data.item.POSITION_NAME,
                                          client: data.item.CLIENT,
                                          attachments: data.item.attachments,
                                          recruiter: data.item.recruiter,
                                          locations: data.item.locations,
                                          sourcer: data.item.sourcer,
                                          am_name: data.item.account_mgr,
                                          position_type: data.item.type,
                                          vertical: data.item.vertical,
                                          category: data.item.category,
                                          payroll: data.item.payroll
                                  }
                                }"
                              >
                      <div class="text-nowrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                          {{ data.item.CANDIDATE_NAME }}<br/>
                          {{ data.item.CAN_ID }}
                      </div>
                  </router-link>
                </template>  
                <template #cell(position_name)="data" >
                <router-link :to="'/apps/view-job/' + data.item.jid">
                <div class="text-wrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial">
                    {{ data.item.POSITION_NAME }} <br/>
                    {{ data.item.JOB_ID }} <br/>
                    {{ data.item.CLIENT }} <br/>
                    {{ data.item.poc }}
                    </div>
                </router-link>
                </template>
               
                <template #cell(recruiter)="data" >
                    <div class="text-nowrap" >
                      <span v-if="data.item.rec_name">{{ data.item.rec_name }} (R)<br/></span>
                      <span v-if="data.item.tl">{{ data.item.tl }} (TL)<br/></span>
                      <span v-if="data.item.p_mgr">{{ data.item.p_mgr }} (PM)<br/></span>
                      <span v-if="data.item.added_by">{{ data.item.added_by }} (A)<br/></span>
                    </div>
                </template>
                <template #cell(added_on)="data" >
                    <div class="text-nowrap" >
                      <span v-if="data.item.added_on">{{ data.item.added_on.split("T")[0] }} (A)<br/></span>
                      <span v-if="data.item.added_on">{{ data.item.updated_on.split("T")[0] }} (U)<br/></span>
                    </div>
                </template>
                <!-- <template #cell(recruiter)="data">
                    <div class="text-nowrap">
                      {{ data.item.scheduled_by }} (A)<br/>
                      {{ data.item.recruiter }} (R)<br/>
                      {{ data.item.sourcer }} (S)<br/>
                      {{ data.item.account_mgr }} (AM)<br/>
                      {{ data.item.team_lead }} (TL)<br/>
                    </div>
                </template> -->
                <template #cell(candidateStatus)="data">
                    <div class="text-nowrap">
                    {{ data.item.candidateStatus }} <br/>
                    {{ data.item.source }} 
                    </div>
                </template>
                <template #cell(status)="data">
                    <div class="text-nowrap">
                      <b-badge pill class="text-capitalize"
                        :class="resolvePOFUStatusVariant(data.item.status)">
                          <span v-if="data.item.status">{{ data.item.status }}</span>
                          <span v-else> NOT INITIATED</span>
                      </b-badge>
                    </div>
                </template>
                <template #cell(comment)="data" >
                    <div class="text-wrap" id="tooltip-comment">
                    {{ data.item.comment }} 
                    <b-tooltip
                        target="tooltip-comment"
                        placement="left"
                        triggers="hover"
                    >
                    {{ data.item.comment }} 
                    </b-tooltip>
                    </div>
                </template>
                
                <template #cell(actions)="data" >
                    <div  class="text-nowrap text-success pb-1" @click="sendCandidateItemToModal(data.item)" 
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-modal.modal-pofu-update v-if="$can('create','pofu')">
                      <b-badge pill class="badge-success" v-if="data.item.status">
                        Update
                      </b-badge>
                      <b-badge pill class="badge-info" v-else>
                        Initiate
                      </b-badge>
                    </div>
                </template>
                
              </b-table>
              </div>
              
              <div v-if="!loading && !compiledData ">
                <span class="text-danger">{{ reportStatus }}</span>
              </div>   
            </div>
          </b-card>  
        </div>
        <!--:title="candidate_id + ' - ' + candidate_name + ' - ' + position + ' - ' + client"-->
        <b-modal
          id="modal-pofu-update"
          ok-title="submit"
          cancel-variant="outline-secondary"
          @ok="updatePOFU"
        >
          <b-form > 
              
              <b-form-group label="Select POFU Status *" label-for="pofu-status">
                <v-select
                    v-model="pofuStatusSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="POFUStatusOptions"
                    label="title"
                    :reduce="(option) => option.title"
                />
              </b-form-group>
              
              <b-form-group
                label="Enter notes"
                label-for="pofu-notes"
              >
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Enter notes/comment"
                  v-model="pofu_notes"
                  rows="3"
                />
              </b-form-group>
            </b-form>
        </b-modal>
        <b-modal
          id="modal-pofu-timeline"
          ok-only
          ok-title="OK"
          centered
          size="xl"
        >
          <b-table responsive="sm" :items="pofuTimeline" :fields="timelineFields"> 
            <template #cell(pofu_id)="data" >
                <div class="text-nowrap">
                  {{ data.item.pofu_id }}
                </div>
            </template>
            <template #cell(added_by)="data" >
                <div class="text-nowrap">
                  {{ data.item.added_by }}
                </div>
            </template>
            <template #cell(status)="data" >
                <div class="text-nowrap">
                  <b-badge pill class="text-capitalize"
                        :class="resolvePOFUStatusVariant(data.item.status)">
                          {{ data.item.status }}
                  </b-badge>
                </div>
            </template>
            <template #cell(comment)="data" >
                <div class="text-wrap">
                  {{ data.item.comment }}
                </div>
            </template>
            <template #cell(added_on)="data" >
                <div class="text-nowrap">
                  {{ data.item.added_on }}
                </div>
            </template>
          </b-table>
        </b-modal>  
    </section>
  </template>
  
  <script>
  import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BSpinner, BFormCheckbox, BFormCheckboxGroup, BTable, BBadge, BModal, VBModal, BFormTextarea, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import {VBTooltip,  BTooltip} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import flatPickr from 'vue-flatpickr-component'
  import vSelect from 'vue-select'
  import moment from 'moment'
  import UserService from '@/services/user-service'
  import clientService from '@/services/client-service'
  import pofuService from '@/services/pofu-service'
  //import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import adminConfigService from "@/services/admin-config-service";
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BForm, 
      BFormGroup,
      BSpinner,
      BFormCheckbox,
      BFormCheckboxGroup,
      BTooltip,
      BFormInput, BInputGroup, BInputGroupAppend,
      // ExportExcelSheetVueJs,
      ToastificationContent,
      BFormTextarea,
      BTable,
      BBadge,
      vSelect, BModal, VBModal, flatPickr,
      
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        //interview_date: '',
        tracker: '',
        compiledData: [],
        loading: false,
        downloadReport: '',
        recruiters: '',
        recSelected: '',
        accountMgr: '',
        mgrSelected: '',
        sourceSelected: '',
        clients: '',
        filter: null,
        sortDirection: 'asc',
        filterOn: [],
        clientSelected: '',
        location: [{ title: 'Pune' }, { title: 'Delhi'}],
        locSelected: '',
        filename: '',
        columns: [{ label: 'Manager', field: 'Manager'}, { label: 'Recruiter', field: 'Recruiter'}, { label: 'Client', field: 'Client'}, { label: 'Location', field: 'Location'}, { label: 'Total Candidates', field: 'TotalCandidates'}, 
          { label: 'Profile Shared', field: 'ProfileShared'}, { label: 'Review Reject', field: 'ReviewReject'}, { label: 'Initiated', field: 'Initiated'}, { label: 'Not Interested', field: 'NotInterested'}, { label: 'Not Relevant', field: 'NotRelevant'}, 
          { label: 'Not Answered', field: 'NotAnswered'}, { label: 'Not Reachable', field: 'NotReachable'}, { label: 'Wrong Number', field: 'WrongNumber'}, { label: 'Call Back', field: 'CallBack'}, { label: 'Screen Select', field: 'ScreenSelect'},
          { label: 'Screen Reject', field: 'ScreenReject'}, { label: 'Duplicate', field: 'Duplicate'}, { label: 'InProcess', field: 'InProcess'}, { label: 'On Hold', field: 'OnHold'}, { label: 'Shortlisted', field: 'Shortlisted'},
          { label: 'Rejected', field: 'Rejected'}, { label: 'Offered', field: 'Offered'}, { label: ' Not Offered', field: 'NotOffered'}, { label: 'Offer Drop', field: 'OfferDrop'}, { label: 'Joined', field: 'Joined'}],
        reportStatus: '',
        
        // options: [
        //   { text: 'Recruiter', value: 'recruiter' },
        //   { text: 'Team Lead', value: 'manager' },
        //   { text: 'Location', value: 'location' },
        //   { text: 'Client', value: 'client' },
        //   { text: 'Source', value: 'source' },
        // ],
        //interviewStatusOptions: [{ title: "Naukri" }, { title: "Monster" }],
        sources: [
          { title: "Naukri" },
          { title: "Monster" },
          { title: "ATS" },
          { title: "LinkedIn" },
          { title: "Indeed" },
          { title: "Shine" },
          { title: "TimesJobs" },
          { title: "IIMJobs" },
          { title: "Glassdoor" },
          { title: "FreshersWorld" },
          { title: "Other" },
          { title: "Train N Hire" },
          { title: "Internal" },
          { title: "Referenced" },
        ],
        sheetname: 'Status Wise Tracker',
        isReadyToDownload: false,
        sourceSelected: '',
        fields: [
          { key: 'pofu_id', label: 'ID', sortable: true, thStyle: { width: "10%" }	 },
          { key: 'candidate_name', label: 'Name', sortable: true, thStyle: { width: "15%" } },
          { key: 'position_name', label: 'Position', sortable: true, thStyle: { width: "15%" } },
          { key: 'recruiter', label: 'Recruiter', sortable: true, thStyle: { width: "10%" } },
          { key: 'added_on', label: 'Updated On', sortable: true, thStyle: { width: "10%" } },
          { key: 'candidateStatus', label: 'Candidate Status', sortable: true, thStyle: { width: "10%" } },
          { key: 'status', label: 'POFU Status', sortable: true, thStyle: { width: "10%" } },
          { key: 'comment', label: 'POFU Comment', sortable: true, thStyle: { width: "10%" } },
          { key: "actions", label: 'Actions', thStyle: { width: "20%" } },
          
        ],
        timelineFields: [
        { key: 'pofu_id', label: 'ID', sortable: true },
          { key: 'added_by', label: 'ADDED BY', sortable: true },
          { key: 'status', label: 'POFU Status', sortable: true },
          { key: "comment", label: 'comment' },
          { key: "added_on", label: 'Added On' },
        ],
        POFUStatusOptions: [
          { title: "On-Track"},
          { title: "Potential Drop"},
          { title: "Drop"},
        ],
        selected: [null,"On-Track","Potential Drop","Drop"],
        POFUOptions: [
          { text: "Not Initiated", value: null},
          { text: "On-Track", value: "On-Track"},
          { text: "Potential Drop", value: "Potential Drop"},
          { text: "Drop", value: "Drop"},
        ],
        pofu_notes: '',
        pofuTimeline: [],
        error: '',
        sortBy: '',
        sortDesc: '',
        pofuItems: [],
        pofuStatusSelected: '',
        //interviewStatusSel: ''
      }
    },
    directives: {
        'b-modal': VBModal,
        'b-tooltip': VBTooltip,
        Ripple,
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
    },
    watch: {
      mgrSelected: function(newMgr) {
          //let cgst = 0.0
          this.filterRecruiters();
          //return this.cgst
      },

    },
    created() {
      this.startDate = moment().locale("en").add(-7, 'days').format("YYYY-MM-DD")
      this.endDate = moment().locale("en").add(7, 'days').format("YYYY-MM-DD")
  
      // UserService.getAllUsersDdActiveInactive().then( res => {
      //   if(res.status === "OK") {
      //     //console.log("in if")
      //     if(user)
      //     this.recruiters = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor");
      //     this.accountMgr = res.data.filter(item => item.role === "account-manager");
      //   }
        
      // })
      this.role = JSON.parse(localStorage.getItem('userData')).roles[0]
      const emp_id = JSON.parse(localStorage.getItem('userData')).emp_id
      const mgr_id = JSON.parse(localStorage.getItem('userData')).mgr_id
      const title = JSON.parse(localStorage.getItem('userData')).first_name + ' ' + JSON.parse(localStorage.getItem('userData')).last_name
      this.recruiters = [{
          "id": 1,
          "title": "All",
          "emp_id": "All"
      }]
      // UserService.getAllUsersDd().then( res => {
      // if(res.status === "OK") {
      //   console.log("in if")
        //   const allUsers = JSON.parse(localStorage.getItem('allusers'))
        //   console.log('allUsers', allUsers)
        //  if (this.role === 'ACCOUNT-MANAGER') {
        //   const self = [{
        //     "id": 111111,
        //     "title": title,
        //     "emp_id": emp_id,
        //     "mgr_id": mgr_id,
        //     "role": this.role
        //   }]
        //   const recs = allUsers.filter(item => (item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase())  && item.manager === emp_id);  
        //   console.log('recs', recs)
        //   this.recruiters = [...this.recruiters, ...self, ...recs]
        //   console.log(this.recruiters)
        //   for (let i = 0; i < this.recruiters.length; i++) {
        //     const id = "'" + this.recruiters[i].emp_id + "'|"
        //     console.log(id)
        //     this.all_ids = this.all_ids + id
        //   }
        //   console.log(this.all_ids)
        //   this.accountMgr = self
        // } else if (this.role === 'TALENT-ADVISOR' || this.role === 'TALENT-ADVISOR-SOURCER') {
        //   const recs = []
        //   this.recruiters = [...this.recruiters, ...recs]
        //   const mgr = allUsers.filter(item => item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() && item.emp_id === mgr_id );
        //   this.accountMgr = [{
        //     "id": 111111,
        //     "title": mgr[0].title,
        //     "emp_id": mgr_id,
        //     "mgr_id": mgr[0].manager,
        //     "role":  mgr[0].role
        //   }]
        //   //this.accountMgr = 
        // } else {
        //   const recs = allUsers.filter(item => item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase());
        //   this.recruiters = [...this.recruiters, ...recs]
        //   this.accountMgr = allUsers.filter(item => item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase())
        // }
        UserService.getAllTeamMembersList(emp_id).then(res =>{
          console.log('recruiters ',res.data)
          this.recruiters = res.data
          for (let i = 0; i < this.recruiters.length; i++) {
                const id = "'" + this.recruiters[i].emp_id + "'|"
                // console.log(id)
                this.all_ids = this.all_ids + id
          }
          this.getReport()
        })
        UserService.getAllManagersList().then(res =>{
          console.log('managers ',res.data)
          this.accountMgr = res.data
          // for (let i = 0; i < this.recruiters.length; i++) {
          //       const id = "'" + this.recruiters[i].emp_id + "'|"
          //       // console.log(id)
          //       this.all_ids = this.all_ids + id
          // }
        })

        console.log(this.recruiters)
      clientService.getClients().then(res => {
        this.clients = res.data
        console.log(this.clients)
      })
      
      
    },
    methods: {
      getReport() {
        if(!this.$can('read','pofu')) {
          return
        }
        this.error = ''
        this.compiledData = []
        this.tracker = []
        const emp_id  = JSON.parse(localStorage.getItem('userData')).emp_id
        const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]

        // if (this.startDate === '' || this.endDate === '') {
        //   this.reportStatus = 'Please Select Dates'
        // } else {
          this.loading = true
          //console.log(this.startDate, this.endDate, moment().locale("en").format("YYYY-MM-DD"))
          //console.log('DATE CHECK', new Date(this.startDate) < new Date(moment().locale("en").format("YYYY-MM-DD")))
          // this.rec = this.selected.includes('recruiter')
          // this.mgr = this.selected.includes('manager')
          // this.loc = this.selected.includes('location')
          // this.client = this.selected.includes('client')
          console.log(this.selected)
          let ids = ""
          console.log(this.recruiterSelected, this.statusSelected)
          // if(userRole === 'ACCOUNT-MANAGER' ) {
          //   this.all_ids = this.all_ids.substring(0, this.all_ids.length-1);
          //   ids = this.recruiterSelected ? (this.recruiterSelected === 'All' ? this.all_ids : this.recruiterSelected) : emp_id
          //   console.log(ids)
          // } else if(userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER') {
          //   ids = emp_id
          //   console.log(ids)
          // } else {
          //   // for admin, master, hr,mis, no condition 
          //   ids = emp_id
          //   console.log(ids)
          // } 

          if(this.$can('read','admin') || this.$can('read','hr') || this.$can('read','master')) {
            // for admin, master, hr,mis, no condition 
            ids = emp_id
            console.log('other ids',ids)
          } else if(this.$can('read','account_manager') ) {
            this.all_ids = this.all_ids.substring(0, this.all_ids.length-1);
            ids = this.recruiterSelected ? (this.recruiterSelected === 'All' ? this.all_ids : this.recruiterSelected) : emp_id
            console.log('account_manager',ids)
          } else if(this.$can('read','talent_acquisition')) {
            ids = emp_id
            console.log('talent advisor',ids)
          }
          
          //console.log(this.rec, this.mgr, this.loc, this.client)
          this.recSelected = this.recSelected == null || this.recSelected == undefined ? '' : this.recSelected
          this.mgrSelected = this.mgrSelected == null || this.mgrSelected == undefined ? '' : this.mgrSelected
          this.locSelected = this.locSelected == null || this.locSelected == undefined ? '' : this.locSelected
          this.clientSelected = this.clientSelected == null || this.clientSelected == undefined ? '' : this.clientSelected
          this.sourceSelected = this.sourceSelected == null || this.sourceSelected == undefined ? '' : this.sourceSelected
          if (new Date(this.startDate) <= new Date(moment().locale("en").format("YYYY-MM-DD")) && (new Date(this.startDate) <= new Date(this.endDate))) {
            pofuService.getAll( this.recSelected, this.mgrSelected, this.clientSelected, this.locSelected , this.sourceSelected, emp_id, userRole, ids).then(res => {
                if (res.status === "OK") {
                  //this.reportStatus = ''

                  if (this.selected.length === 4 ) {
                  this.compiledData = res.data
                  this.totalRows = res.data.length
                  } else if(this.selected.length > 0) {

                    for (let i = 0; i < this.selected.length; i++) {
                      const data = res.data.filter( item => item.status === this.selected[i])
                      this.compiledData = [...this.compiledData, ...data]
                    }
                  } else {
                      this.compiledData = res.data
                  }
                //this.compiledData = res.data
                this.isReadyToDownload = true
                console.log(res)
                //this.downloadReport = 'data:application/xlsx;base64,' + res.file
                this.filename = 'POFU Tracker.xls'
                this.loading = false
  
                if(this.compiledData.length === 0) {
                  this.error = 'No Data found'
                }
              } else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                this.reportStatus = 'Session Expired... Redirecting to Login Page'
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
              } else if ((res.status === 400 ) ) {
                this.reportStatus = 'No Data Found for Selected Dates'
                this.loading = false
                this.compiledData = null
              }
              else if ((res.status === 500 ) ) {
                this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
                this.loading = false
                this.compiledData = null
              }
            })
          } else if (new Date(this.startDate) > new Date(this.endDate)) {
            this.loading = false
            this.reportStatus = 'Start Date cannot be greater than End Date!!!'
          }
            else {
            this.loading = false
            this.reportStatus = 'Start Date cannot be greater than today!!!'
          }
        //}  
      },
      updatePOFU() {
        this.error = ''
        //if(this.interview_date) {

          const userData = JSON.parse(localStorage.getItem("userData"));
          // const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
          // const username = `${userData.first_name} ${userData.last_name}`
          console.log('pofuStatusSelected', this.pofuStatusSelected)
          const data = {
            candidate_id: this.cid,
            job_id: this.jobid,
            added_by: userData.emp_id,
            status: this.pofuStatusSelected,
            parent_id: this.pofu_id,
            comment: this.pofu_notes,
            recruiter: this.recruiter,
            tl: this.tl,
            p_mgr: this.p_mgr,
            position_name: this.position,
            client: this.client,
            cname: this.cname
            
          }
          console.log(data)
          pofuService.create(data).then(res => {
            if (res.status === "OK") {
              //this.reportStatus = ''
              console.log(res)
              //this.downloadReport = 'data:application/xlsx;base64,' + res.file
              this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: "POFU "+ this.pofuStatusSelected +" !!!",
                            icon: "EditIcon",
                            variant: "success",
                            },
                        });
              this.getReport()                        
              
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 500 ) ) {
              this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: "POFU "+ this.pofuStatusSelected +" Failed!!!",
                            icon: "EditIcon",
                            variant: "danger",
                            },
                        });
              this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
          })
        //   //} else {
        //   this.error = 'Interview Date is Mandatory'
        //   }
      },
      showPOFUTimeline(data) {
        this.error = ''
        console.log('cid-jid', data.cid, data.jid, data.parent_id)
        const pid = data.parent_id ? data.parent_id : data.pofu_id
        pofuService.getCandidateJobPOFUs(data.cid, data.jid, pid).then(res => {
            if (res.status === "OK") {
              console.log(res)
              this.pofuTimeline = res.data

              
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 500 ) ) {
              this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: "Interview details get Failed!!!",
                            icon: "EditIcon",
                            variant: "danger",
                            },
                        });
              this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
          })
      },
      sendCandidateItemToModal(data) {

        
        this.pofuStatusSelected = data.status
        console.log('status', this.pofuStatusSelected)
        this.cid = data.cid
        this.jobid = data.jid
        this.cname = data.CAN_ID + ' ' + data.CANDIDATE_NAME
        this.position = data.JOB_ID + ' ' + data.POSITION_NAME
        this.client = data.CLIENT
        this.currentStatus = data.candidateStatus
        this.recruiter = JSON.parse(localStorage.getItem("allusers")).filter(item => item.emp_id === data.recruiter)[0]
        this.tl = JSON.parse(localStorage.getItem("allusers")).filter(item => item.emp_id === data.mgr_id)[0]
        this.p_mgr = JSON.parse(localStorage.getItem("allusers")).filter(item => item.emp_id === data.p_mgr_id)[0]
        //this.interview_date = data.date_time
        //this.interview_status = data.status
        //this.pofu_notes = data.comment
        this.pofu_id = data.parent_id ? data.parent_id : data.pofu_id
        // this.revenue = data.revenue
        // this.revenue_confirmed = data.revenue_confirmed
        },
      numDaysBetween(d1, d2) {
          var diff = Math.abs(d1.getTime() - d2.getTime());
          return diff / (1000 * 60 * 60 * 24);
      } ,
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        //this.currentPage = 1
      }, 
      resolvePOFUStatusVariant(status) {
      //console.log("called")
      //const resolveCandidateStatusVariant = status => {
        
        if (status === 'On-Track') return 'badge-success'
        if (status === 'Potential Drop') return 'badge-warning'
        if (status === 'Drop') return 'badge-danger'
        return 'badge-secondary'
      },
      filterRecruiters() {
        const allUsers = JSON.parse(localStorage.getItem('allusers'))
        this.recruiters = [{
          "id": 1,
          "title": "All",
          "emp_id": "All"
        }]
        const recs = allUsers.filter(item => (item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase()) && (item.emp_id === this.mgrSelected || item.manager === this.mgrSelected));
        this.recruiters = [...this.recruiters, ...recs]
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      clearSelected() {
        this.selected = []
      }, 
    },
    updated: function () {
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
           const y = JSON.parse(scrollPosition).y
           

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
    },
    mounted() {
      adminConfigService.getATSDropdownList().then(res => {
        if(res.status === 200) {
          this.sources = res.data.data.sourceList
          // this.qualifications = res.data.data.qualificationList
        }
      })
      // this.getReport();
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  <style scoped>
  table, td {
    border: 1px solid rgb(97, 97, 97);
    border-collapse: collapse;
  }
  .col-my10 {
    width: 10% !important;
  }
  .col-my14 {
    width: 14% !important;
  }
  .col-my16 {
    width: 16% !important;
  }
  .col-my6 {
    width: 6% !important;
  }
  .col-my84 {
    width: 84% !important;
  }
  .col-my17 {
    width: 16.6% !important;
  }
  .col-my20 {
    width: 19.1% !important;
  }
  .col-my7 {
    width: 7.1447% !important;
  }
  .col-my40 {
    width: 40% !important;
  }
  .text-v-h-center {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
  }
  td {
    word-wrap: break-word !important;
    max-width: 15% !important;
  }
  </style>
  